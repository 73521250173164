/* THIS IS A SAMPLE FOR ADDING NEW BRANCHES
import Sanlorenzo from '../img/example.jpg'; */

import Alaminos from '../img/branches/alaminos.jpg';
// import Alfonso from '../img/branches/alfonso.jpg';
import Angeles from '../img/branches/angeles.jpg';
import Antipolo from '../img/branches/antipolo.jpg';
import Apalit from '../img/branches/apalit.jpg';
import Bacoor from '../img/branches/bacoor.jpg';
import Baliuag from '../img/branches/baliuag.jpg';
import Bataan from '../img/branches/bataan.jpg';
// import Binangonan from '../img/branches/binangonan.jpg';
import Cabanatuan from '../img/branches/cabanatuan.jpg';
// import Carcar from '../img/branches/carcar.jpg';
import Castillejos from '../img/branches/castillejos.jpg';
// import CebuNorth from '../img/branches/cebunorth.jpg';
// import CebuSouth from '../img/branches/cebusouth.jpg';
import Consolacion from '../img/branches/consolacion.jpg';
import Cubao from '../img/branches/cubao.jpg';
import Daraga from '../img/branches/daraga.jpg';
// import Dasmariñas from '../img/branches/dasmarinas.jpg';
import DasmaxGentri from '../img/branches/dasmaxgentri.jpg';
// import DavaoTalomo from '../img/branches/davao_talomo.jpg';
// import Dumaguete from '../img/branches/dumaguete.jpg';
import Fairview from '../img/branches/fairview.jpg';
import GAraneta from '../img/branches/g_araneta.jpg';
// import GeneralTrias from '../img/branches/general_trias.jpg';
// import GMACavite from '../img/branches/gma_cavite.jpg';
import Guimba from '../img/branches/guimba.jpg';
import Iloilo from '../img/branches/iloilo.jpg';
import Imus from '../img/branches/imus.jpg';
import Irosin from '../img/branches/irosin.jpg';
import LasPiñas from '../img/branches/las_pinas.jpg';
import Legazpi from '../img/branches/legazpi.jpg';
import Lipa from '../img/branches/lipa.jpg';
import Malolos from '../img/branches/malolos.jpg';
import Mandaluyong from '../img/branches/mandaluyong.jpg';
import Manila1 from '../img/branches/manila_1.jpg';
import Manila2 from '../img/branches/manila_2.jpg';
import Marikina from '../img/branches/marikina.jpg';
// import Marilao from '../img/branches/marilao.jpg';
import Meycauayan from '../img/branches/meycauayan.jpg';
import Midsayap from '../img/branches/midsyap.jpg';
import Monumento from '../img/branches/monumento.jpg';
// import Muntinlupa from '../img/branches/muntinlupa.jpg';
// import Nabua from '../img/branches/nabua.jpg';
import Naga from '../img/branches/naga.jpg';
import NorthCaloocan from '../img/branches/north_caloocan.jpg';
import Parañaque from '../img/branches/paranaque.jpg';
import Pasig from '../img/branches/pasig.jpg';
import Pateros from '../img/branches/pateros.jpg';
// import Polangui from '../img/branches/polangui.jpg';
import Roxas from '../img/branches/roxas.jpg';
import SanCarlos from '../img/branches/san_carlos.jpg';
import SanFernando from '../img/branches/san_fernando.jpg';
import SanJose from '../img/branches/san_jose.jpg';
// import SanRafael from '../img/branches/san_rafael.jpg';
// import SantoDomingo from '../img/branches/santo_domingo.jpg';
import Sorsogon from '../img/branches/sorsogon.jpg';
import Tabaco from '../img/branches/tabaco.jpg';
import Talisay from '../img/branches/talisay.jpg';
import TandangSora from '../img/branches/tandang_sora.jpg';
import Tarlac1 from '../img/branches/tarlac_1.jpg';
import Tarlac2 from '../img/branches/tarlac_2.jpg';
import Taytay from '../img/branches/taytay.jpg';
import Urdaneta from '../img/branches/urdaneta.jpg';
import Valenzuela from '../img/branches/valenzuela.jpg';
import Virac from '../img/branches/virac.jpg';


// eslint-disable-next-line import/no-anonymous-default-export
export default [

    {
        id: 1,
        branch_name: 'Alaminos, Pangasinan',
        link: '/alaminos',
        image: Alaminos,
        address: '2nd Flr. Y&Y Bldg., Magsaysay, Alaminos City, Pangasinan',
        embed_map: 'https://maps.google.com/maps?q=16.1714438,119.9887609&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Alaminos],
        contact_no: [],
        email: []
    },
    // {
    //     id: 2,
    //     branch_name: 'Alfonso, Cavite',
    //     link: '/alfonso',
    //     image: Alfonso,
    //     address: '2nd Flr. No.4, Mabini St., Poblacion IV, Alfonso Cavite',
    //     embed_map: 'https://maps.google.com/maps?q=14.121213318500057,%20120.86253452976516&t=&z=15&ie=UTF8&iwloc=&output=embed',
    //     listImages: [Alfonso],
    //     contact_no: ['0915-975-9997(Globe/TM)', '0936-776-2061(Globe/TM)'],
    //     email: ['catesaruta@gmail.com']
    // },
    {
        id: 3,
        branch_name: 'Angeles',
        link: '/angeles',
        image: Angeles,
        address: 'Unit 6 2nd floor NJT Bldg. Infront of Sta. Maria Church, Balibago, Angeles City',
        embed_map: 'https://maps.google.com/maps?q=NJT%20BLDG.Sta.Maria%20Ave.Corner%20Mac%20Arthur%20Hi-%20Way,%20Balibago,%20Angeles%20City,%20Pampanga&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Angeles],
        contact_no: ['09493662929'],
        email: ['ltgangelespampanga@gmail.com']
    },
    {
        id: 4,
        branch_name: 'Antipolo',
        link: '/antipolo',
        image: Antipolo,
        address: '',
        embed_map: 'https://maps.google.com/maps?q=NJT%20BLDG.Sta.Maria%20Ave.Corner%20Mac%20Arthur%20Hi-%20Way,%20Balibago,%20Angeles%20City,%20Pampanga&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Antipolo],
        contact_no: [],
        email: []
    },
    {
        id: 5,
        branch_name: 'Apalit, Pampanga',
        link: '/apalit',
        image: Apalit,
        address: '3rd Flr. St. Jude Bldg., Brgy. San Vicente, Apalit, Pampanga',
        embed_map: 'https://maps.google.com/maps?q=14.950224214819032,%20120.75863640473877&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Apalit],
        contact_no: ['0961-664-4947(Smart)', '0946-105-8051(TNT)', '0955-317-6475(Globe/TM)'],
        email: ['edwinesteller3@gmail.com']
    },
    {
        id: 6,
        branch_name: 'Bacoor',
        link: '/bacoor',
        image: Bacoor,
        address: 'Blk 17 Lot 2 Niog Road Meadowood Executive Village P.F Espiritu VI Panapaan Bacoor, Cavite ',
        embed_map: '',
        listImages: [Bacoor],
        contact_no: [''],
        email: ['']
    },
    {
        id: 7,
        branch_name: 'Baliuag, Bulacan',
        link: '/baliuag',
        image: Baliuag,
        address: '0713, New 3rd floor Plaza Naning, Poblacion, Baliuag, Bulacan',
        embed_map: 'https://maps.google.com/maps?q=14.952133709752925,%20120.89663387775275&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Baliuag],
        contact_no: ['0975-425-8155(Globe/TM)'],
        email: ['maecruz2017@gmail.com']
    },
    {
        id: 8,
        branch_name: 'Bataan',
        link: '/bataan',
        image: Bataan,
        address: '2nd Flr. Brick Corner Bldg. Paterno St., Poblacion City Of Balanga, Bataan 2100',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.6422997512736!2d120.54073441436995!3d14.676229879154535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33964008fc64dfff%3A0x20566539dd06631!2sSt.+Joseph+Pre-School+of+Balanga!5e0!3m2!1sen!2sph!4v1559726807487!5m2!1sen!2sph',
        listImages: [Bataan],
        contact_no: ['0929-147-6049(Smart)'],
        email: ['michellnicodemus.jmcbataan@gmail.com']
    },
    // {
    //     id: 9,
    //     branch_name: 'Binangonan, Rizal',
    //     link: '/binangonan',
    //     image: Binangonan,
    //     address: '858-A National Rd., Brgy. Calumpang Binangonan, Rizal',
    //     embed_map: 'https://maps.google.com/maps?q=14.53037720414258,%20121.22850637722097&t=&z=13&ie=UTF8&iwloc=&output=embed',
    //     listImages: [Binangonan],
    //     contact_no: ['0920-200-2012(Smart)', '0968-2189-410(Smart)'],
    //     email: ['ltgcreditlinebinangonan001@gmail.com', 'santosmariaivypoche@gmail.com']
    // },
    {
        id: 10,
        branch_name: 'Cabanatuan',
        link: '/cabanatuan',
        image: Cabanatuan,
        address: 'Door 1, RFG bldg. Del. Pilar St. Fatima Cabanatuan City',
        embed_map: 'https://maps.google.com/maps?q=14.53037720414258,%20121.22850637722097&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Cabanatuan],
        contact_no: [],
        email: []
    },
    // {
    //     id: 11,
    //     branch_name: 'Carcar, Cebu',
    //     link: '/carcar',
    //     image: Carcar,
    //     address: 'Cebu Massive Auto Parts Inc. Awayan, Poblacion 3, Carcar City, Cebu',
    //     embed_map: 'https://maps.google.com/maps?q=10.109154332527128,%20123.64985031207807&t=&z=17&ie=UTF8&iwloc=&output=embed',
    //     listImages: [Carcar],
    //     contact_no: ['0927-038-7208(Globe)', '0963-902-5764(Smart/TNT)'],
    //     email: ['snookybctoledo@gmail.com']
    // },
    {
        id: 12,
        branch_name: 'Castillejos',
        link: '/castillejos',
        image: Castillejos,
        address: 'Umipig Building, 98 National Highway, San Juan, Castillejos, Zambales 2208',
        embed_map: 'https://maps.google.com/maps?q=10.109154332527128,%20123.64985031207807&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Castillejos],
        contact_no: [],
        email: []
    },
    // {
    //     id: 13,
    //     branch_name: 'Cebu City North',
    //     link: '/cebunorth',
    //     image: CebuNorth,
    //     address: '',
    //     embed_map: 'https://maps.google.com/maps?q=10.2945903,123.8705041&t=&z=13&ie=UTF8&iwloc=&output=embed',
    //     listImages: [CebuNorth],
    //     contact_no: [],
    //     email: []
    // },
    // {
    //     id: 14,
    //     branch_name: 'Cebu South',
    //     link: '/cebusouth',
    //     image: CebuSouth,
    //     address: 'South Drive 1212 Tres de Abril St., Punta Princesa, Cebu City, Cebu',
    //     embed_map: 'https://maps.google.com/maps?q=10.2945903,123.8705041&t=&z=13&ie=UTF8&iwloc=&output=embed',
    //     listImages: [CebuSouth],
    //     contact_no: ['268-9218'],
    //     email: ['ltgcebusouth071822@gmail.com']
    // },
    {
        id: 15,
        branch_name: 'Consolacion',
        link: '/consolacion',
        image: Consolacion,
        address: 'Ground Floor KJC Building Purok 7 Pitogo, Consolacion, Cebu',
        embed_map: 'https://maps.google.com/maps?q=10.49936973537122,%20124.0309502022266&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Consolacion],
        contact_no: ['0927-386-0140(Globe)'],
        email: ['pononisheil@gmail.com']
    },
    {
        id: 16,
        branch_name: 'Cubao',
        link: '/cubao',
        image: Cubao,
        address: '',
        embed_map: 'https://maps.google.com/maps?q=10.49936973537122,%20124.0309502022266&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Cubao],
        contact_no: ['0927-386-0140(Globe)'],
        email: ['pononisheil@gmail.com']
    },
    {
        id: 17,
        branch_name: 'Daraga',
        link: '/daraga',
        image: Daraga,
        address: '2nd floor Calderon Bldg. Alcala St. Brgy. Sipi, Daraga Albay',
        embed_map: 'https://maps.google.com/maps?q=13.154161369356771,%20123.71129261259618&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Daraga],
        contact_no: ['0955-397-6367(Globe/TM)'],
        email: ['']
    },
    // {
    //     id: 18,
    //     branch_name: 'Dasmariñas, Cavite',
    //     link: '/dasmariñas',
    //     image: Dasmariñas,
    //     address: '201 Unit 2nd Floor Osrem Building Brgy. Tejero General Trias Cavite',
    //     embed_map: 'https://maps.google.com/maps?q=14.326963673601076,%20120.93521737389156&t=&z=17&ie=UTF8&iwloc=&output=embed',
    //     listImages: [Dasmariñas],
    //     contact_no: ['0970-937-3870(Smart)'],
    //     email: ['jeorgiereyniadelmendo@gmail.com', 'joseph_barra@yahoo.com']
    // },
    // {
    //     id: 19,
    //     branch_name: 'Davao Talomo',
    //     link: '/davaotalomo',
    //     image: DavaoTalomo,
    //     address: '2F Gabucan Bldg. McArthur Highway, Ulas Crossing, Talomo, Davao City',
    //     embed_map: 'https://maps.google.com/maps?q=14.326963673601076,%20120.93521737389156&t=&z=17&ie=UTF8&iwloc=&output=embed',
    //     listImages: [DavaoTalomo],
    //     contact_no: ['0970-937-3870(Smart)'],
    //     email: ['jeorgiereyniadelmendo@gmail.com', 'joseph_barra@yahoo.com']
    // },

    // {
    //     id: 20,
    //     branch_name: 'Dumaguete',
    //     link: '/dumaguete',
    //     image: Dumaguete,
    //     address: 'Door 3, 2nd Floor, B3 Bldg., Real St. North Road National Highway, Daro, Dumaguete City, Negros Oriental, 6200',
    //     embed_map: 'https://maps.google.com/maps?q=9.317950641096154,%20123.30136098909685&t=&z=17&ie=UTF8&iwloc=&output=embed',
    //     listImages: [Dumaguete],
    //     contact_no: ['0946-305-7554(TNT)', '0931-826-0655(Sun)'],
    //     email: ['reinsky_ems07@gmail.com']
    // },
    {
        id: 21,
        branch_name: 'Fairview',
        link: '/fairview',
        image: Fairview,
        address: '',
        embed_map: 'https://maps.google.com/maps?q=9.317950641096154,%20123.30136098909685&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Fairview],
        contact_no: ['0946-305-7554(TNT)', '0931-826-0655(Sun)'],
        email: ['reinsky_ems07@gmail.com']
    },
    {
        id: 22,
        branch_name: 'G. Araneta, QC',
        link: '/garaneta',
        image: GAraneta,
        address: '3rd floor Rm. 305 Pasda bldg. 9 P. Florentino St. , Cor. G. Araneta Ave. Brgy. Sto Domingo, Quezon City ( Matalahib)',
        embed_map: 'https://maps.google.com/maps?q=14.629667848181024,%20121.01184429336334&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [GAraneta],
        contact_no: ['0916-617-9259(Globe)'],
        email: ['sharky.santos@gmail.com', 'ltgcredit.onesm01@gmail.com', 'onesm.paolobobier@gmail.com']
    },
    {
        id: 23,
        branch_name: 'General Trias, Cavite / Dasmariñas',
        link: '/generaltrias',
        image: DasmaxGentri,
        address: '201 Unit 2nd Floor Osrem Building Brgy. Tejero General Trias Cavite',
        embed_map: 'https://maps.google.com/maps?q=14.286094,121.0010818&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [DasmaxGentri],
        contact_no: [],
        email: []
    },
    // {
    //     id: 24,
    //     branch_name: 'GMA,Cavite',
    //     link: '/gmacavite',
    //     image: GMACavite,
    //     address: 'Area K Block 2 Lot 24 , San Sebastian St. , Brgy. San Gabriel , Gen. Mariano Alvarez , Cavite',
    //     embed_map: 'https://maps.google.com/maps?q=14.286094,121.0010818&t=&z=13&ie=UTF8&iwloc=&output=embed',
    //     listImages: [GMACavite],
    //     contact_no: [],
    //     email: []
    // },
    {
        id: 25,
        branch_name: 'Guimba, Nueva Ecija',
        link: '/guimba',
        image: Guimba,
        address: '',
        embed_map: 'https://maps.google.com/maps?q=14.286094,121.0010818&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Guimba],
        contact_no: [],
        email: []
    },
    {
        id: 26,
        branch_name: 'Iloilo City',
        link: '/iloilo',
        image: Iloilo,
        address: '3/F PAFCPIC Building Brgy. Buhang Taft North Mandurriao, Iloilo City, 5000',
        embed_map: 'https://maps.google.com/maps?q=10.717751202697267,%20122.54854398342569&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Iloilo],
        contact_no: [''],
        email: ['']
    },
    {
        id: 27,
        branch_name: 'Imus, Cavite',
        link: '/imus',
        image: Imus,
        address: '',
        embed_map: '',
        listImages: [Imus],
        contact_no: [''],
        email: ['']
    },
    {
        id: 28,
        branch_name: 'Irosin',
        link: '/irosin',
        image: Irosin,
        address: '2F Francisco Fuller Commercial Bldg., Bonifacio St., Brgy. San Juan, Irosin, Sorsogon',
        embed_map: 'https://maps.google.com/maps?q=12.7023842,124.0357762&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Irosin],
        contact_no: ['09519326835 (Smart/TNT)'],
        email: ['ltgirosin@gmail.com']
    },
    {
        id: 29,
        branch_name: 'Las Piñas',
        link: '/laspiñas',
        image: LasPiñas,
        address: '',
        embed_map: '',
        listImages: [LasPiñas],
        contact_no: [''],
        email: ['']
    },

    {
        id: 30,
        branch_name: 'Legazpi',
        link: '/legazpi',
        image: Legazpi,
        address: '2nd floor, Unit C, CQ Bldg. 3 Phase 2, Rosal Street, Imperial Court, Brgy. 24, Rizal, Legazpi City',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.83043504317686!2d123.75241813738648!3d13.14429053493856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a1033328711cbd%3A0x9bc11ac59ca83bb1!2sCQ%20Bldg%203!5e0!3m2!1sen!2sph!4v1654047357304!5m2!1sen!2sph',
        listImages: [Legazpi],
        contact_no: ['0995-104-6267(Globe)', '0960-867-0566(Smart)', '0917-531-3862(Globe/TM)'],
        email: ['franchise@ltgcreditline.com', 'franchise@mamsltg.com']
    },
    {
        id: 31,
        branch_name: 'Lipa',
        link: '/lipa',
        image: Lipa,
        address: '2nd Flr. Unit 206 Dream 8 Plaza Bldg. , San Sebastian (balagbag) , Lipa City , Batangas.',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.83043504317686!2d123.75241813738648!3d13.14429053493856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a1033328711cbd%3A0x9bc11ac59ca83bb1!2sCQ%20Bldg%203!5e0!3m2!1sen!2sph!4v1654047357304!5m2!1sen!2sph',
        listImages: [Lipa],
        contact_no: [''],
        email: ['']
    },
    {
        id: 32,
        branch_name: 'Malolos',
        link: '/malolos',
        image: Malolos,
        address: '',
        embed_map: 'https://maps.google.com/maps?q=14.5950546,121.0278049&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Malolos],
        contact_no: [''],
        email: ['']
    },
    {
        id: 33,
        branch_name: 'Mandaluyong',
        link: '/mandaluyong',
        image: Mandaluyong,
        address: '2/F Unit V, TFN Building, 963 Gen Kalentong Cor. Haig St, Daang Bakal, Mandaluyong, 1550 Metro Manila',
        embed_map: 'https://maps.google.com/maps?q=14.5950546,121.0278049&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Mandaluyong],
        contact_no: ['0947-329-3789(Smart)'],
        email: ['']
    },
    {
        id: 32,
        branch_name: 'Manila 1',
        link: '/manila1',
        image: Manila1,
        address: '3rd Flr. Room 305 DMV Bldg. Herbosa St. Tondo, Manila',
        embed_map: 'https://maps.google.com/maps?q=14.618515734298182,%20120.96448760288433&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Manila1],
        contact_no: ['0910-539-5384(TNT)', "0907-815-1615(TNT)"],
        email: ['lornafampulme@yahoo.com']
    },
    {
        id: 33,
        branch_name: 'Manila 2 ',
        link: '/manila2',
        image: Manila2,
        address: 'The Station Point Bldg., 3294 R. Magsaysay Blvd., Corner Maganda St., Zone 43 Brgy. 427 Sampaloc, Manila',
        embed_map: 'https://maps.google.com/maps?q=14.601900566744707,%20121.00495676795397&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Manila2],
        contact_no: ['0910-539-5384(TNT)'],
        email: ['lornafampulme@yahoo.com']
    },

    {
        id: 34,
        branch_name: 'Marikina',
        link: '/marikina',
        image: Marikina,
        address: '2nd Flr. Castillo Bldg. 50 General Ordoñez Ave., Brgy. Concepcion Uno, Marikina City',
        embed_map: 'https://maps.google.com/maps?q=14.64614380742703,%20121.11265601517296&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Marikina],
        contact_no: ['0999-990-6949(Smart)'],
        email: ['jbploanyourblessings@outlook.com', 'sherylramosltg@gmail.com', 'marikinaltgcreditline@gmail.com']
    },
    // {
    //     id: 35,
    //     branch_name: 'Marilao, Bulacan',
    //     link: '/marilao',
    //     image: Marilao,
    //     address: '',
    //     embed_map: '',
    //     listImages: [Marilao],
    //     contact_no: [''],
    //     email: ['']
    // },
    {
        id: 36,
        branch_name: 'Meycauayan',
        link: '/meycauayan',
        image: Meycauayan,
        address: 'Unit 201 933 ABZ Bldg. Mc Arthur Hi Way Calvario Meycauayan, Bulacan',
        embed_map: 'https://maps.google.com/maps?q=14.5950546,121.0278049&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Meycauayan],
        contact_no: [''],
        email: ['']
    },
    {
        id: 37,
        branch_name: 'Midsayap',
        link: '/midsayap',
        image: Midsayap,
        address: '2nd floor JL Feliciano Bldg., Quezon Avenue, Poblacion 5, Midsayap North Cotabato',
        embed_map: 'https://maps.google.com/maps?q=14.64614380742703,%20121.11265601517296&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Midsayap],
        contact_no: ['0999-990-6949(Smart)'],
        email: ['jbploanyourblessings@outlook.com', 'sherylramosltg@gmail.com', 'marikinaltgcreditline@gmail.com']
    },
    {
        id: 38,
        branch_name: 'Monumento',
        link: '/monumento',
        image: Monumento,
        address: '3rd Floor Ok Plaza Bldg., Brgy. 75 Samson Rd., Monumento, Caloocan City',
        embed_map: 'https://maps.google.com/maps?q=14.737705886261061,%20120.9604839624881&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Monumento],
        contact_no: ['0932-965-3121(Sun)'],
        email: ['ltgcreditlinemalabon.hr@gmail.com']
    },
    // {
    //     id: 39,
    //     branch_name: 'Muntinlupa',
    //     link: '/muntinlupa',
    //     image: Muntinlupa,
    //     address: '',
    //     embed_map: '',
    //     listImages: [Muntinlupa],
    //     contact_no: [''],
    //     email: ['']
    // },
    // {
    //     id: 40,
    //     branch_name: 'Nabua',
    //     link: '/nabua',
    //     image: Nabua,
    //     address: '2nd Flr. 5 Peyra Bldg., San Francisco, Nabua Camarines Sur',
    //     embed_map: 'https://maps.google.com/maps?q=13.411171401296697,%20123.37951424294387&t=&z=17&ie=UTF8&iwloc=&output=embed',
    //     listImages: [Nabua],
    //     contact_no: ['0936-323-4823(Globe/TM)', '0951-456-5631(Smart)'],
    //     email: ['ltgnabua.02@gmail.com', 'dabegailfatima@gmail.com']
    // },
    {
        id: 41,
        branch_name: 'Naga',
        link: '/naga',
        image: Naga,
        address: '2nd Flr. Unit 10 Zone 5 MMCN Bldg., Panganiban Drive Concepcion Pequeña, Naga City',
        embed_map: 'https://maps.google.com/maps?q=13.62178897153642,%20123.19738677163974&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Naga],
        contact_no: ['0936-323-4823(Globe/TM)', '0938-554-5877(TNT)'],
        email: ['kheavalenzuela20@gmail.com', 'joseph_barra@yahoo.com']
    },

    {
        id: 42,
        branch_name: 'North Caloocan',
        link: '/northcaloocan',
        image: NorthCaloocan,
        address: '4GFM Bldg. 1634 Ilang-Ilang St., Camarin, Caloocan City',
        embed_map: 'https://maps.google.com/maps?q=Brgy%20174%20Camarin,%20Caloocan%20City&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [NorthCaloocan],
        contact_no: ['0917-174-1970(Globe/TM)'],
        email: ['ltgcreditline.nc3@gmail.com', 'jbploanyourblessings@outlook.com', 'alicejbpinc@gmail.com']
    },
    {
        id: 43,
        branch_name: 'Parañaque',
        link: '/paranaque',
        image: Parañaque,
        address: '3709 Sun Valley Drive, Brgy. Sun Valley Parañaque City',
        embed_map: 'https://maps.google.com/maps?q=Samar%20Bldg,.Brgy.%20Gabon,Polangui,%20Albay&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Parañaque],
        contact_no: ['0975-981-3400(Globe/TM)'],
        email: ['']
    },
    {
        id: 44,
        branch_name: 'Pasig',
        link: '/pasig',
        image: Pasig,
        address: '',
        embed_map: '',
        listImages: [Pasig],
        contact_no: [''],
        email: ['']
    },
    {
        id: 45,
        branch_name: 'Pateros',
        link: '/pateros',
        image: Pateros,
        address: '2nd Floor A&L De Borja Realty Inc., 29 Bagong Kalsada St., Martirez St., Del 96 Pateros, Metro Manila',
        embed_map: 'https://maps.google.com/maps?q=Samar%20Bldg,.Brgy.%20Gabon,Polangui,%20Albay&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Pateros],
        contact_no: ['0975-981-3400(Globe/TM)'],
        email: ['']
    },
    // {
    //     id: 46,
    //     branch_name: 'Polangui',
    //     link: '/polangui',
    //     image: Polangui,
    //     address: 'Gonzales Building, Centro Oriental (Pob), Polangui, Albay',
    //     embed_map: 'https://maps.google.com/maps?q=Samar%20Bldg,.Brgy.%20Gabon,Polangui,%20Albay&t=&z=13&ie=UTF8&iwloc=&output=embed',
    //     listImages: [Polangui],
    //     contact_no: ['0975-981-3400(Globe/TM)'],
    //     email: ['']
    // },
    {
        id: 47,
        branch_name: 'Roxas',
        link: '/roxas',
        image: Roxas,
        address: '2nd Floor SRE Arcade, San Roque Extension, Brgy VIII, Roxas City Capiz 5800',
        embed_map: 'https://maps.google.com/maps?q=Samar%20Bldg,.Brgy.%20Gabon,Polangui,%20Albay&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Roxas],
        contact_no: ['0975-981-3400(Globe/TM)'],
        email: ['']
    },
    {
        id: 48,
        branch_name: 'San Carlos, Pangasinan',
        link: '/sancarlos',
        image: SanCarlos,
        address: '',
        embed_map: 'https://maps.google.com/maps?q=12.9755663,124.0109265&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [SanCarlos],
        contact_no: ['09101459070 (Smart/TNT)'],
        email: ['ltgcreditlinecorp.sorsogon@gmail.com']
    },
    {
        id: 49,
        branch_name: 'San Fernando, Pampanga',
        link: '/sanfernando',
        image: SanFernando,
        address: '',
        embed_map: 'https://maps.google.com/maps?q=12.9755663,124.0109265&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [SanFernando],
        contact_no: ['09101459070 (Smart/TNT)'],
        email: ['ltgcreditlinecorp.sorsogon@gmail.com']
    },
    {
        id: 50,
        branch_name: 'San Jose City, Nueva Ecija',
        link: '/sanjose',
        image: SanJose,
        address: '',
        embed_map: 'https://maps.google.com/maps?q=12.9755663,124.0109265&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [SanJose],
        contact_no: ['09101459070 (Smart/TNT)'],
        email: ['ltgcreditlinecorp.sorsogon@gmail.com']
    },
    // {
    //     id: 51,
    //     branch_name: 'San Rafael, Bulacan',
    //     link: '/sanrafael',
    //     image: SanRafael,
    //     address: '161 Doña Dalisay Bldg. Capihan San Rafael Bulacan.',
    //     embed_map: 'https://maps.google.com/maps?q=12.9755663,124.0109265&t=&z=13&ie=UTF8&iwloc=&output=embed',
    //     listImages: [SanRafael],
    //     contact_no: ['09101459070 (Smart/TNT)'],
    //     email: ['ltgcreditlinecorp.sorsogon@gmail.com']
    // },
    // {
    //     id: 52,
    //     branch_name: 'Sto. Domingo, Ilocos Sur',
    //     link: '/santodomingo',
    //     image: SantoDomingo,
    //     address: '2F D&A Bldg. , Maharlika Hi-way , Poblacion , Sto. Domingo , Ilocos Sur.',
    //     embed_map: 'https://maps.google.com/maps?q=17.6360428,120.4070836&t=&z=13&ie=UTF8&iwloc=&output=embed',
    //     listImages: [SantoDomingo],
    //     contact_no: [],
    //     email: []
    // },
    {
        id: 53,
        branch_name: 'Sorsogon',
        link: '/sorsogon',
        image: Sorsogon,
        address: '3F, Garcia Bldg., Alegre St., Balogo, Sorsogon City',
        embed_map: 'https://maps.google.com/maps?q=12.9755663,124.0109265&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Sorsogon],
        contact_no: ['09101459070 (Smart/TNT)'],
        email: ['ltgcreditlinecorp.sorsogon@gmail.com']
    },

    {
        id: 54,
        branch_name: 'Tabaco',
        link: '/tabaco',
        image: Tabaco,
        address: '2F, JHEM Building, P-4 Tagas, Tabaco City',
        embed_map: 'https://maps.google.com/maps?q=13.351735,123.7315086&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Tabaco],
        contact_no: [''],
        email: ['ltgcreditlinecorp.tabaco@gmail.com']
    },
    {
        id: 55,
        branch_name: 'Talisay, Cebu',
        link: '/talisay',
        image: Talisay,
        address: '2nd Floor, ARBS Bldg. Dumlog, Talisay City',
        embed_map: 'https://maps.google.com/maps?q=9.644703547442893,%20123.85856777953848&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Talisay],
        contact_no: ['0995-471-9323(Globe)', '0910-441-6897(TNT)'],
        email: ['Christine.ibaoc@gmail.com']
    },
    {
        id: 56,
        branch_name: 'Tandang Sora',
        link: '/tandang-sora',
        image: TandangSora,
        address: 'Unit 103, LRI Bldg. #21 Congressional Ave, Bahay Toro, Quezon City',
        embed_map: 'https://maps.google.com/maps?q=21%20Congressional%20Ave.,%20Brgy.%20Bahay%20Toro,%20Quezon%20City&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [TandangSora],
        contact_no: ['0949-881-8911(Smart)'],
        email: ['ltgcreditline.tandangsora@gmail.com', 'jennethareglo@gmail.com', 'jbploanyourblessings@outlook.com']
    },
    {
        id: 57,
        branch_name: 'Tarlac 1',
        link: '/tarlac1',
        image: Tarlac1,
        address: '2nd Flr. M. Suarez Commercial Bldg. , Plaridel St. , San Jose , Concepcion , Tarlac.',
        embed_map: 'https://maps.google.com/maps?q=10.38602223035746,%20123.6496607983151&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Tarlac1],
        contact_no: ['0927-038-7208(Globe/TM)', '0963-001-8803(Smart/TNT)'],
        email: ['snookybctoledo@gmail.com']
    },
    {
        id: 58,
        branch_name: 'Tarlac 2',
        link: '/tarlac2',
        image: Tarlac2,
        address: '',
        embed_map: 'https://maps.google.com/maps?q=9.9491959826641,%20123.96051978933511&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Tarlac2],
        contact_no: ['0946-284-4734(TNT)', '0938-999-7003(TNT)'],
        email: ['metzijeand@gmail.com']
    },
    {
        id: 59,
        branch_name: 'Taytay',
        link: '/taytay',
        image: Taytay,
        address: '',
        embed_map: '',
        listImages: [Taytay],
        contact_no: [''],
        email: ['']
    },
    {
        id: 60,
        branch_name: 'Urdaneta',
        link: '/urdaneta',
        image: Urdaneta,
        address: 'Mc Arthur Highway, San Vicente, Urdaneta City, Pangasinan,Philippines.',
        embed_map: 'https://maps.google.com/maps?q=2F%20Rivera%20Bldg.,8%20Maysan%20Road,%20Valenzuela%20City&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Urdaneta],
        contact_no: ['0932-263-9960(Sun)'],
        email: ['ltgcreditlinemalabon.hr@gmail.com']
    },
    {
        id: 61,
        branch_name: 'Valenzuela',
        link: '/valenzuela',
        image: Valenzuela,
        address: '#84 Karuhatan road ,Unit 2D Arca Commercial Place',
        embed_map: 'https://maps.google.com/maps?q=14.5950546,121.0278049&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Valenzuela],
        contact_no: [''],
        email: ['']
    },
    {
        id: 62,
        branch_name: 'Virac',
        link: '/virac',
        image: Virac,
        address: '2F Talan Bldg. Brgy. San Pablo, Virac, Catanduanes',
        embed_map: 'https://maps.google.com/maps?q=13.5781648,124.2305331&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Virac],
        contact_no: ['09318815566 (Smart/TNT)'],
        email: ['ltgviracbranch@gmail.com']
    },


    /* test for new option. and adding of new branches.
    {
        id: 57,
        branch_name: 'San Lorenzo  ',
        link: '/valenzuela',
        image: Sanlorenzo,
        address: 'Room 5, 2F Rivera Bldg.,8 Maysan Road, Valenzuela City',
        embed_map: 'https://maps.google.com/maps?q=2F%20Rivera%20Bldg.,8%20Maysan%20Road,%20Valenzuela%20City&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Sanlorenzo],
        contact_no: ['0932-263-9960(Sun)'],
        email: ['ltgcreditlinemalabon.hr@gmail.com']
    } */

    // https://www.embedgooglemap.net/
];


